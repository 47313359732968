<template>
  <div>
    <div class="zx-load-col"/>
    <div class="zx-load-col"/>
    <div class="zx-load-col"/>
    <div class="zx-load-col"/>
    <div class="zx-load-col"/>
    <div class="zx-load-col"/>
  </div>
</template>

<script>
  export default {
    name: 'LoadingAnimation',
  }
</script>

<style scoped lang="scss">
  @keyframes sweep {
    0% {
      transform: scaleY(.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .zx-load-col {
    margin-top: 30px;
    background: #352997;
    text-align: center;
    display: inline-block;
    height: 100px;
    width: 20px;

    transform-origin: 0 100%;
    animation: sweep 1s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite both alternate;

    &:nth-child(1) {
      animation-delay: .05s
    }

    &:nth-child(2) {
      animation-delay: .1s
    }

    &:nth-child(3) {
      animation-delay: .15s
    }

    &:nth-child(4) {
      animation-delay: .2s
    }

    &:nth-child(5) {
      animation-delay: .25s
    }

    &:nth-child(6) {
      animation-delay: .3s
    }
  }
</style>